<button
  lf-secondary-button
  lf-icon-button
  #showInsightsButton
  [ngClass]="{ active: activeInsightsButton() }"
  (click)="showInsights()">
  <i class="lf-icon lf-icon-bell"></i>
</button>

<span class="counter">
  {{ counter() }}
</span>
