import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  inject,
  signal,
} from '@angular/core';
import { CATEGORY } from '@tokens/category.token';
import { CategoryType } from '@type/categories.type';
import { PageTab } from '../../interfaces/tab.interface';

@Component({
  selector: 'app-base-tab',
  template: '',
  styleUrls: ['./base-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class BaseTabComponent implements PageTab {
  private elRef = inject(ElementRef);

  protected category = inject(CATEGORY);

  element = this.elRef.nativeElement as HTMLElement;

  isActive = signal(false);

  @Input()
  data!: CategoryType;

  @Input()
  name: string = '';

  @Input()
  isPinned = false;

  @HostBinding('style.height')
  get height() {
    return getComputedStyle(this.elRef.nativeElement).getPropertyValue('--page-tab-bar-height');
  }

  @HostBinding('class')
  cssClass = 'page-tab';

  @HostBinding('class.active')
  get active() {
    return this.category.value()?.id === this.data?.id;
  }

  abstract clickHandler(event: Event): void;

  @HostListener('click', ['$event'])
  click(event: Event) {
    this.clickHandler(event);
  }
}
