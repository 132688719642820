import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { loadFeatureTranslation, withFeatureTranslation } from '@lf/translate-core';
import { USER } from '@tokens/user.token';
import { MenuItem } from '@type/menu.type';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { SubmenuDirective } from '../submenu/submenu.directive';
import { UserSpaceSubmenuComponent } from '../user-space-submenu/user-space-submenu.component';

@Component({
  selector: 'app-menu',
  imports: [RouterModule, MenuItemComponent, SubmenuDirective],
  providers: [withFeatureTranslation({ fileName: 'menu' })],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@loadFeatureTranslation('menu')
export class MenuComponent {
  private user = inject(USER);

  topMenu: MenuItem[] = [
    {
      name: '',
      url: '/',
      iconName: 'logo',
      restrict: computed(() => !this.user()),
    },
    {
      name: 'dashboard',
      url: 'dashboard',
      iconName: 'dashboard',
      restrict: computed(() => this.user().dashboardHide),
    },
    {
      name: 'matrix',
      url: '',
      iconName: 'matrix',
      restrict: computed(() => this.user().matrixAndMatrixHistoryHide),
      childUrls: [
        {
          name: 'assortment_matrix',
          url: '/matrix/assortment-matrix',
          restrict: computed(() => this.user().matrixAndMatrixHistoryHide),
        },
        {
          name: 'matrix_history',
          url: '/matrix/history',
          restrict: computed(() => this.user().matrixAndMatrixHistoryHide),
        },
      ],
    },
    {
      name: 'w_matrix',
      url: '',
      iconName: 'w-matrix',
      restrict: computed(() => this.user().matrixWarehousesHide),
      childUrls: [
        {
          name: 'warehouse_matrix',
          url: '/w-matrix',
          restrict: computed(() => this.user().matrixWarehousesHide),
        },
        {
          name: 'warehouse_matrix_history',
          url: '/w-matrix/history',
          restrict: computed(() => this.user().matrixWarehousesHide),
        },
      ],
    },
    {
      name: 'strategy',
      url: 'strategy',
      iconName: 'strategy',
      restrict: computed(() => this.user().strategyHide),
    },
    {
      name: 'statistic',
      url: '/statistic/sku-statistic',
      iconName: 'statistic',
      restrict: computed(() => this.user().statisticHide),
    },
    {
      name: 'classifier',
      url: '',
      iconName: 'classifier',
      restrict: computed(() => this.user().classifierAndFoldersHide && this.user().priceSegmentationHide),
      childUrls: [
        {
          name: 'classifier',
          url: 'classifier',
          restrict: computed(() => this.user().classifierAndFoldersHide),
        },
        {
          name: 'price_segment',
          url: 'segmentation',
          restrict: computed(() => this.user().priceSegmentationHide),
        },
      ],
    },
    {
      name: 'clustering',
      url: 'clustering',
      iconName: 'clustering',
      restrict: computed(() => this.user().clusterizationHide),
    },
    {
      name: 'log',
      url: '',
      iconName: 'log',
      restrict: computed(() => this.user().logHide),
      childUrls: [
        {
          name: 'assortment_matrix',
          url: '/log/matrix',
          restrict: computed(() => this.user().logHide),
        },
        {
          name: 'warehouse_matrix',
          url: '/log/warehouse',
          restrict: computed(() => this.user().logHide),
        },
        {
          name: 'clusterization_log',
          url: '/log/clusterization',
          restrict: computed(() => this.user().logHide),
        },
        {
          name: 'settings_log',
          url: '/log/settings',
          restrict: computed(() => this.user().logHide),
        },
        {
          name: 'exchange_log',
          url: '/log/exchange',
          restrict: computed(() => this.user().logHide),
        },
      ],
    },
  ];

  bottomMenu: MenuItem[] = [
    {
      name: 'settings',
      url: 'settings',
      iconName: 'settings',
      restrict: computed(() => this.user().settingsAllHide),
    },
    {
      name: 'user',
      url: '',
      iconName: 'user',
      menuComponent: UserSpaceSubmenuComponent,
      restrict: () => false,
    },
  ];
}
