import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { withAvailableFieldForm, withAvailableFields } from '@modules/components/available-fields/services/provider';
import { StrategyPermissionFullPipe } from '@permissions/strategy-permission-full/strategy-permission-full.pipe';
import { TimerService } from '@services/timer/timer.service';
import { SEARCH_CONTROL_PROVIDER } from '@tokens/search-control.token';
import { USER } from '@tokens/user.token';
import { ReplaySubject } from 'rxjs';
import { StrategyFieldsService } from '../../modules/components/available-fields/services/strategy-fields/strategy-fields.service';
import { StrategyFormService } from '../../modules/components/available-fields/services/strategy-form/strategy-form.service';
import { BUTTON_CONFIG } from '../clustering/resolvers/button-config.resolver';
import { MatrixClustersService } from '../matrix/services/matrix-clusters/matrix-clusters.service';
import { MatrixStoresService } from '../matrix/services/matrix-stores/matrix-stores.service';
import { StrategyApiService } from './services/strategy-api/strategy-api.service';
import { StrategyClusterService } from './services/strategy-cluster-service/strategy-cluster-service.service';
import { StrategyEntityVisibilityService } from './services/strategy-entity-visibility/strategy-entity-visibility.service';
import { StrategyLimitApiService } from './services/strategy-limit-api/strategy-limit-api.service';
import { StrategyLimitFormService } from './services/strategy-limit-form/strategy-limit-form.service';
import { StrategyLimitStateService } from './services/strategy-limit-state/strategy-limit-state.service';
import { StrategyStateService } from './services/strategy-state/strategy-state.service';
import { StrategyTableSettingsService } from './services/strategy-table-settings-form/strategy-table-settings-form.service';
import { StrategyTreeService } from './services/strategy-tree/strategy-tree.service';
import { TableProcessService } from './services/table-process/table-process.service';

export const PROVIDERS = [
  SEARCH_CONTROL_PROVIDER,
  withAvailableFields(StrategyFieldsService),
  withAvailableFieldForm(StrategyFormService),
  StrategyApiService,
  StrategyStateService,
  StrategyLimitApiService,
  StrategyLimitFormService,
  StrategyTreeService,
  StrategyLimitStateService,
  TableProcessService,
  StrategyTableSettingsService,
  {
    provide: BUTTON_CONFIG,
    useValue: new ReplaySubject(1),
  },
  {
    provide: MatrixStoresService,
    useClass: StrategyEntityVisibilityService,
  },
  {
    provide: MatrixClustersService,
    useClass: StrategyClusterService,
  },
  StrategyPermissionFullPipe,
  TimerService,
];

export const canMatchCreateStrategyRoute = () => {
  const user = inject(USER);
  const router = inject(Router);
  return user().strategyFull ? true : router.navigate(['strategy', 'list']);
};
