import { CheckboxComponent } from '@abm/controls';
import { IconComponent } from '@abm/icon';
import { DecimalPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { FilterService } from '@modules/filter/services/filter/filter.service';
import { FilterBean } from '@modules/filter/types/filter';
import { FilterType } from '@modules/filter/types/filter-options';
import { ID_IN } from '@modules/filter/utils/filter-type';
import { MatrixEntityType } from '@modules/product-matrix/types/matrix';
import { InsightsPermissionFullPipe } from '@permissions/insights-permission-full/insights-permission-full.pipe';
import { MATRIX_HEADER_FORM, MatrixViewType } from '@tokens/forms/matrix-header-form.token';
import { OVERLAY_REF } from '@tokens/overlay-ref.token';
import { InsightIcon, InsightItem } from '@type/insight.type';
import { NamedType } from '@type/named-item.type';
import { tomorrow } from '@utils/date/date';
import { getInsightIconFn } from '@utils/insight';
import { SelectOptionsList } from '@utils/select-option-list';

@Component({
  selector: 'app-header-insight-item',
  imports: [NgClass, FormsModule, CheckboxComponent, IconComponent, InsightsPermissionFullPipe],
  templateUrl: './header-insight-item.component.html',
  styleUrls: ['./header-insight-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderInsightItemComponent {
  private router = inject(Router);

  private matrixForm = inject(MATRIX_HEADER_FORM);

  private overlayRef = inject(OVERLAY_REF);

  private decimalPipe = inject(DecimalPipe);

  private filterService = inject(FilterService);

  private matrixViewTypeList = new SelectOptionsList([
    MatrixViewType.create(environment.urls.stores_matrix as MatrixEntityType),
    MatrixViewType.create(environment.urls.clusters_matrix as MatrixEntityType),
  ]).list;

  icon: InsightIcon = 'listing';

  @Input({ required: true })
  get insight(): InsightItem {
    return this.#insight;
  }
  set insight(value: InsightItem) {
    value.message = value.message.replaceAll(/\d+(\.\d+)?/g, (match: string) => {
      return this.decimalPipe.transform(match) || match;
    });
    this.#insight = value;
    this.icon = this.getIcon(value);
  }

  @Output()
  insightChange = new EventEmitter<InsightItem>();

  #insight!: InsightItem;

  getIcon: ReturnType<typeof getInsightIconFn> = getInsightIconFn();

  @HostBinding('class.new')
  get isNew() {
    return !this.insight?.is_read;
  }

  goToMatrix(insight: InsightItem) {
    const route = this.getRoute(insight.insight_for);

    this.matrixForm.patchValue({
      date: tomorrow().toISOString(),
      view: this.matrixViewTypeList[insight.insight_for === 'stores' ? 0 : 1] as NamedType,
    });
    this.overlayRef?.dispose();
    this.router
      .navigate(route, {
        onSameUrlNavigation: 'reload',
        queryParams: {
          insightType: insight.insight_for,
        },
      })
      .then(() => this.setInsightToFilter(insight.product_ids));
  }

  private setInsightToFilter(value: number[]) {
    setTimeout(() => {
      this.filterService.set({ [ID_IN]: new FilterBean(FilterType.Insight, { model: 'id_in', name: '', value }) });
    }, 100);
  }

  private getRoute(insightFor: string) {
    return insightFor === 'warehouses' ? ['w-matrix', 'matrix'] : ['matrix', 'assortment-matrix'];
  }
}
