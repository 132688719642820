import { inject, Injectable } from '@angular/core';
import { MatrixEntityResponseKey } from '@modules/product-matrix/types/matrix';
import { joinUrls } from '@utils/urls';
import { MatrixEntityVisibilityService } from 'src/app/pages/matrix/services/matrix-entity-visibility/matrix-entity-visibility.service';
import { StrategyStateService } from '../strategy-state/strategy-state.service';

@Injectable()
export class StrategyClusterService extends MatrixEntityVisibilityService {
  private strategy = inject(StrategyStateService).strategy;

  visivilityKey = 'clusters_visibility' as const;

  requestUrl: string = joinUrls('strategies', String(this.strategy()?.id), 'clusters');

  responseKey: MatrixEntityResponseKey = 'clusters';
}
