import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, inject, Input } from '@angular/core';
import { MenuTooltipComponent } from '@modules/components/menu-tooltip/menu-tooltip.component';
import { MENU_ITEM } from '@tokens/menu-item.token';
import { MenuItem } from '@type/menu.type';
import { createInjectorFn } from '@utils/create-provider';
import { SubmenuComponent } from '../submenu-item/submenu.component';

@Directive({
  selector: 'app-menu-item[menuItem], [submenu]',
})
export class SubmenuDirective {
  @Input()
  menuItem!: MenuItem;

  private createInjector: ReturnType<typeof createInjectorFn> = createInjectorFn();

  private overlay = inject(Overlay);

  private elementRef = inject(ElementRef);

  private overlayRef: OverlayRef | undefined;

  private get isWithSubmenu(): boolean {
    return !!this.menuItem.childUrls?.length;
  }

  private get isWithSubmenuComponent(): boolean {
    return !!this.menuItem.menuComponent;
  }

  @HostListener('mouseenter')
  mouseenter(): void {
    if (!this.isWithSubmenu && !this.isWithSubmenuComponent) {
      if (!this.overlayRef?.hasAttached() && !!this.menuItem.name) {
        this.overlayRef = this.createTooltipOverlay();
      }
    } else {
      if (!this.overlayRef?.hasAttached()) {
        this.overlayRef = this.createSubmenuOverlay();
      }
    }
  }

  @HostListener('mouseleave', ['$event'])
  mouseleave(event: MouseEvent): void {
    if ((this.isWithSubmenu || this.isWithSubmenuComponent) && event.relatedTarget) {
      if (!this.isOverlayElementContainsNode(event.relatedTarget as Node)) {
        this.overlayRef?.detach();
      }
    } else {
      this.overlayRef?.detach();
    }
  }

  private isOverlayElementContainsNode(node: Node): boolean {
    return !!this.overlayRef?.overlayElement.contains(node);
  }

  private createSubmenuOverlay(): OverlayRef {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.getSubmenuPositionStrategy(),
      disposeOnNavigation: true,
      height: 'auto',
    });

    const component = this.isWithSubmenuComponent ? this.menuItem.menuComponent! : SubmenuComponent;

    const portal = this.createComponentPortalWithInjector(component);

    this.overlayRef.attach(portal);

    return this.overlayRef;
  }

  private createTooltipOverlay(): OverlayRef {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.getTooltipPositionStrategy(),
      disposeOnNavigation: true,
    });

    const portal = this.createComponentPortalWithInjector(MenuTooltipComponent);

    this.overlayRef.attach(portal);

    return this.overlayRef;
  }

  private createComponentPortalWithInjector<T>(component: ComponentType<T>): ComponentPortal<T> {
    return new ComponentPortal<T>(
      component,
      null,
      this.createInjector([
        {
          provide: MENU_ITEM,
          useValue: this.menuItem,
        },
        {
          provide: OverlayRef,
          useValue: this.overlayRef,
        },
      ]),
    );
  }

  private getSubmenuPositionStrategy() {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);

    return positionStrategy;
  }

  private getTooltipPositionStrategy() {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          offsetX: 6,
        },
      ]);

    return positionStrategy;
  }
}
